.text-purple { color: #7A60E4!important }
.text-blue { color: #243e63!important }
.text-white { color: white!important }
.text-gray { color: #7C8BA1 !important }
.text-black { color: black!important }

.font-w300 { font-weight: 300!important }
.font-w400 { font-weight: 400!important }
.font-w600 { font-weight: 600!important }
.font-w700 { font-weight: 700!important }
.font-w900 { font-weight: 900!important }

.font-xl { font-size: 50px }
.font-lg { font-size: 40px }
.font-md { font-size: 30px }
.font-sm { font-size: 20px }
.font-xs { font-size: 13px }

.bg-lightgray { background: #EAEAEA }
.bg-white { background: #fff }
.bg-black { background: #000 }

.fill-gd-magenta { fill: #7A60E4 }

* {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    font-feature-settings: 'ss02' on;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: #F3F3F3;
}

a {
    transition: 300ms;
    color: inherit;

    &:hover {
        text-decoration: none;
        opacity: 0.75;
        transition: 300ms;
        color: inherit;
    }
}

span {
    font: inherit;
}

#navbar {
    background-color: #f3f3f3 !important;
    padding: 40px 13%;
    margin-bottom: 5px;
    transition: 600ms;
    width: 100vw;
    z-index: 1000;

    .navbar-brand {
        margin: 0;
        padding: 0;
        
        img {
            height: 45px;
        }
    }

    .navbar-nav {
        margin-left: auto;
    }

    .nav-link {
        margin: 0 2.75em;
        text-align: center;
        font-weight: 600;
        transition: 500ms;
    }

    .navbar-collapse {
        margin-top: 10px;
    }

    .mobile-nav {
        transform: translateX(150%);
        transition: 200ms ease-in-out;
        border: 1px solid #D7D7D7;
        border-radius: 5px;
        background-color: white;
        padding: 40px 20px;
        position: fixed;
        width: 89vw;
        top: 5vw;
        left: 5vw;
        right: 5vw;

        &.show {
            transform: translateX(0);
            transition: 200ms ease-in-out;
        }
    }
}

#header-content {
    padding: 2% 10%;
}

.btn {
    height: 50px;
    min-width: 200px;
    border-radius: 100px;
    border: none;

    &.btn-sm {
        height: 35px;
        min-width: 160px;
    }

    &.btn-md {
        min-width: 160px;
    }

    &.btn-lg {
        height: 60px;
        min-width: 250px;
    }

    &:hover {
        color: white;
    }
}

.btn-primary {
    background: #389BF8;

    &:hover {
        background: darken(#389BF8, 10%);
    }
}

.btn-light {
    background: rgba(255, 255, 255, 0.4);
    color: white;
}

.btn-purple {
    background: #7A60E4;
    color: white;
}

.btn-black {
    background: #000;
    color: white;
}

.btn-white {
    background: white;
    color: black;

    &:hover {
        background: darken(#ffffff, 10%);
        color: #000;
    }
}

.dept-select-btn {
    background-color: transparent;
    border: none;
    text-align: left;
    font-size: 20px;
    padding: 10px 0;

    &.active {
        font-weight: 600;
        color: #7A60E4;
    }
}

.card {
    box-shadow: 0 4px 30px rgba(198, 198, 198, 0.25);
    border-radius: 0;
    border: none;
    padding: 4%;
    transition: transform 0.5s ease;

    &:hover {
        transform: translateY(-5px);
        transition: transform 0.5s ease;
    }
}

.brand1{
    color: #fff;
}

@media (max-width: 1199px) {
    #navbar {
        padding: 40px 10%;
    }
    .navbar-light {
        background-color: white;
    }
    .nav-link {
        color: #2B2B2B!important;
    }

    .job{
        font-size: 180%;
    }

    .jobsmall{
        font-size: 125%;
    }

}
